import { AppBar, CircularProgress, Button, IconButton, Toolbar, Typography, Box, useScrollTrigger, Slide } from "@mui/material"
import { useEffect, useState } from "react"
type NavType = {
    title?: string
    leftView?: JSX.Element,
    rightView?: JSX.Element,
}


export const Nav = (props: NavType) => {

    useEffect(() => {
        (async () => {

        })()
    }, [])


    return (
        <>
        <AppBar
            color='inherit'
            position="static">
            <Toolbar>
                {props.leftView}
                <Typography
                    align={'center'}
                    color={'primary'}
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                >
                    {props.title}
                </Typography>
                {props.rightView}
            </Toolbar>
        </AppBar>
    </>
    )
}
