
import { ApiConfig, ApiConfigType } from "./api-config"
import { AxiosInstance } from 'axios'
import { createHttp } from './http';
import *as result from "./api-result-type";
import *as Parameters from "./api-parameters-type";
import *as res from "./api-res-type";
import config from "../config";
import { DeleteKioskVendingUserStarsResultData, GetHeadlthTipListResultData, GetKioskInventoryListResultData, GetLabelListResultData, GetLabelResultData, GetMessageRecordListResultData, GetMessageRecordResultData, GetMetadateResultData, PostKioskVendingUserStarsResultData, PostPushDeviceUserResultData, PutMessageRecordResultData } from "./api-result-data-test";

export class Api {

  config: ApiConfigType
  http: AxiosInstance

  constructor (config: ApiConfigType = ApiConfig) {
    this.config = config
    this.http = createHttp(this.config)
  }

  async getMetadate(): Promise<result.GetMetadateResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await GetMetadateResultData()
      }
      const response = await this.http.get('/v2/admin/metadata')
      const data: res.GetMetadateRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }



  async GetKioskInventoryList(): Promise<result.GetKioskInventoryListResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await GetKioskInventoryListResultData()
      }
      const response = await this.http.get('/v3/app/kiosk-public/inventory')
      const data: res.GetKioskInventoryListRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }



  async GetLabelList(): Promise<result.GetLabelListResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await GetLabelListResultData()
      }
      const response = await this.http.get(`/v3/app/label`)
      const data: res.GetLabelListRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }


  async GetLabel(params: Parameters.GetLabelParameters): Promise<result.GetLabelResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await GetLabelResultData()
      }
      const response = await this.http.get(`/v3/app/label/${params.id}`)
      const data: res.GetLabelRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }

  async PostAuthPasswordLogin(params: Parameters.PostAuthPasswordLoginParameters): Promise<result.PostAuthPasswordLoginResult> {
    try {
      const response = await this.http.post('/v3/app/auth/password/login', params)
      const data: res.PostAuthPasswordLoginRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }




  async PostAppAuthFamilyIdLogin(params: Parameters.PostAppAuthFamilyIdLoginParameters): Promise<result.PostAppAuthFamilyIdLoginResult> {
    try {
      const response = await this.http.post('/v3/app/auth/main-family-id/login', params)
      const data: res.PostAppAuthFamilyIdLoginRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }

  async PostAuthPasswordChangeBySms(params: Parameters.PostAuthPasswordChangeBySmsParameters): Promise<result.PostAuthPasswordChangeBySmsResult> {
    try {
      const response = await this.http.post('/v3/app/auth/password/change-by-sms', params)
      const data: res.PostAuthPasswordChangeBySmsRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }

  async PostAppAuthPasswordChangeByPassword(params: Parameters.PostAppAuthPasswordChangeByPasswordParameters): Promise<result.PostAppAuthPasswordChangeByPasswordResult> {
    try {
      const response = await this.http.post('/v3/app/auth/password/change-by-password', params)
      const data: res.PostAppAuthPasswordChangeByPasswordRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }

  async PostAppAuthSmsRequest(params: Parameters.PostAppAuthSmsRequestParameters): Promise<result.PostAppAuthSmsRequestResult> {
    try {
      const response = await this.http.post('/v3/app/auth/sms/request', params)
      const data: res.PostAppAuthSmsRequestRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }

  async DeleteAppAuth(): Promise<any> {
    try {
      const response = await this.http.delete('/v3/app/auth')
      const data = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }


  async GetAppUserMeV3(): Promise<result.GetAppUserMeResult> {
    try {
      const response = await this.http.get('/v3/app/user/me',)
      const data: res.GetAppUserMeRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }

  async GetAppUserMeV4(): Promise<result.GetAppUserMeResult> {
    try {
      const response = await this.http.get('/v4/app/user/me',)
      const data: res.GetAppUserMeRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }

  async PutAppUserMe(params: Parameters.PutAppUserMeParameters): Promise<result.PutAppUserMeResult> {
    try {
      const response = await this.http.put('/v3/app/user/me', params)
      const data: res.PutAppUserMeRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }

  async GetAppFoodMenuList(params: Parameters.GetAppFoodMenuListParameters): Promise<result.GetAppFoodMenuListResult> {
    try {
      const response = await this.http.get('/v3/app/food-menu', { params })
      const data: res.GetAppFoodMenuListRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }
  async GetAppFoodMenu(params: Parameters.GetAppFoodMenuParameters): Promise<result.GetAppFoodMenuResult> {
    try {
      const response = await this.http.get('/v3/app/food-menu', { params })
      const data: res.GetAppFoodMenuRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }



  async GetHeadlthTipList(): Promise<result.GetHeadlthTipListResult> {
    try {

      if (config.isEnableTestAPIData) {
        return await GetHeadlthTipListResultData()
      }

      const response = await this.http.get(`/v3/app/health-tip`)
      const data: res.GetHeadlthTipListRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }





  async GetMessageRecordList(params: Parameters.GetMessageRecordListParameters): Promise<result.GetMessageRecordListResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await GetMessageRecordListResultData()
      }
      const response = await this.http.get(`/v3/app/message/record`, { params })
      const data: res.GetMessageRecordListRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }
  async GetMessageRecord(params: Parameters.GetMessageRecordParameters): Promise<result.GetMessageRecordResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await GetMessageRecordResultData()
      }
      const response = await this.http.get(`/v3/app/message/record/${params.id}`, { params })
      const data: res.GetMessageRecordRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }
  async PutMessageRecord(params: Parameters.PutMessageRecordParameters): Promise<result.PutMessageRecordResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await PutMessageRecordResultData()
      }
      const response = await this.http.put(`/v3/app/message/record/${params.id}`, params)
      const data: res.PutMessageRecordRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }


  async PostPushDeviceUser(params: Parameters.PostPushDeviceUserParameters): Promise<result.PostPushDeviceUserResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await PostPushDeviceUserResultData()
      }
      const response = await this.http.post(`/v3/app/push-device-user`, params)
      const data: res.PostPushDeviceUserRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }



  async PostKioskVendingUserStars(params: Parameters.PostKioskVendingUserStarsParameters): Promise<result.PostKioskVendingUserStarsResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await PostKioskVendingUserStarsResultData()
      }
      const response = await this.http.post(`/v3/app/kiosk-vending-user-stars`, params)
      const data: res.PostKioskVendingUserStarsRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }

  async DeleteKioskVendingUserStars(params: Parameters.DeleteKioskVendingUserStarsParameters): Promise<result.DeleteKioskVendingUserStarsResult> {
    try {
      if (config.isEnableTestAPIData) {
        return await DeleteKioskVendingUserStarsResultData()
      }
      const response = await this.http.delete(`/v3/app/kiosk-vending-user-stars/${params.kioskVendingId}`)
      const data: res.DeleteKioskVendingUserStarsRes = response.data
      return { kind: "ok", data }
    } catch (response: any) {
      return { kind: response.data.errorCode || '' }
    }
  }


}


