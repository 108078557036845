export default {
  "            <DrawerItem disabled={false} onPress={() => this._onActions('COOK_CHILL_MEALS')} title={I18n.t('homeDrawer__COOK_CHILL_MEALS')} accessoryRight={IconAngleRight} />": "DRAWER_ITEM_DISABLED_FALSE_ON_PRESS_THIS_ON_ACTIONS_COOK_CHILL_MEALS_TITLE_I_18_N_T_HOME_DRAWER_COOK_CHILL_MEALS_ACCESSORY_RIGHT_ICON_ANGLE_RIGHT",

  "Auth_ButtonSmsFirst": "First Time Login",
  "Auth_ButtonSmsForget": "Forgot Password",

  "FoodCardItem__beginAt": "Start Date",
  "FoodCardItem__dayInfo": "Quota today",
  "FoodCardItem__endAt": "End Date",
  "FoodCardItem__latestUseAt": "Last used",
  "FoodCardItem__monthInfo": "Quota this month",
  "FoodCardItem__weekInfo": "Quota this week",

  "FoodMenuItem__footText": "This is the meal list after today's replenishment",
  "FoodMenuItem__headerMore": "Upcoming",
  "FoodMenu__title": "Menu",

  "HomeDrawer__exitModalCancel": "Cancel",
  "HomeDrawer__exitModalDefine": "Confirm",
  "HomeDrawer__exitModalMessage": "Are you sure you want to log out?",
  "HomeDrawer__exitModalTitle": "Log out",

  "Home__foodCardUserMemberViewTitle": "Your Family Membership Cards (%{number})",
  "Home__foodMenuViewTitle": "Today's Menu",

  "Home__kioskInventoriesTitle": "AFDS Machines near you",

  "Home__labelButton": "Nutrition Labels",

  "MessageRecordList__headerRightTitle": "%{number} unread",
  "MessageRecordList__listEmptyComponentTitle": "No messages",
  "MessageRecordList__tabTitleSystem": "System",
  "MessageRecordList__tabTitleUser": "Personal",
  "MessageRecordList__title": "Messages",

  "OrderDetailsScreenTitle": "Order details",

  "OrderScreenTitle": "My order",

  "PasswordChangeByPassword__onSubmitSuccess": "Modified successfully",
  "PasswordChangeByPassword__passwordNewAInputPlaceholder": "Please enter a new password",
  "PasswordChangeByPassword__passwordOldInputPlaceholder": "Please input a password",
  "PasswordChangeByPassword__title": "Change Password",
  "PasswordChangeBySms__INCORRECT_PASSWORD": "The password is incorrect. Please try again",
  "PasswordChangeBySms__INCORRECT_SMS_CODE": "SMS verification code is incorrect",
  "PasswordChangeBySms__INVALID_USER": "Mobile number does not exist",
  "PasswordChangeBySms__TOO_MUCH_TIME": "The request is too frequent. Please try again in 60 seconds",
  "PasswordChangeBySms__footButton": "Already set up your password?",
  "PasswordChangeBySms__loginButton": "Confirm password",
  "PasswordChangeBySms__passwordInputPlaceholder": "New password",
  "PasswordChangeBySms__renderPasswordCaption": "Please enter a new password of 6-16 characters",
  "PasswordChangeBySms__smsInputRightButton": "Send SMS",
  "PasswordChangeBySms__smsInputRightButtonByNumber": "Resend (%{number})",
  "PasswordChangeBySms__title": "Set password",

  "PasswordLogin__footButton": "First time to login?",
  "PasswordLogin__loginButton": "Sign in",
  "PasswordLogin__passwordInputPlaceholder": "Please enter the password that has been set",
  "PasswordLogin__phoneInputPlaceholder": "Your registered phone no.",
  "PasswordLogin__renderPasswordCaption": "This is the password you have chosen in the first time",
  "PasswordLogin__smsByPasswordButton": "Set password",
  "PasswordLogin__smsInputPlaceholder": "SMS code",
  "PasswordLogin__title": "Password Login",

  "ProductDetails_label": "Labels",
  "ProductDetails_product": "Products",

  "ProductLabel__title": "Nutrition Labels",

  "ShopCartListRendertItemContetUserQuota": "Limit purchases %{userquota} ",

  "Tel": "Tel",

  "UserMessageModal__ButtonClose": "Close",
  "UserMessageModal__ButtonNext": "Next",
  "UserMessageModal__textSurplus": "Unread remaining (%{count})",
  "UserMessageModal__title": "New Message",

  "UserScreenTitle": "Personal info",

  "Web": "Website",

  "addShopCartCampaignProductHasInventory": "Sorry, we don't have enough stock.",
  "addShopCartCampaignProductHasUserQuota": "Sorry! The purchase limit has been reached",
  "addShopCartCampaignProductRecentOrderAllowedAt": "You have passed the list recently. Please put it on the list later.",

  "appName": "Food Angel AFDS",

  "authButtonSms": "Mobile No ",

  "authInfoPrivacyPolicy": "Privacy policy",
  "authInfoTermsOfUse": "Terms of use",
  "authInfoTitle": "By continuing to use , you agree to comply",

  "authSelectAuthText": "Please select your registration or login method",

  "authSmsInputCaption": "Please enter the correct telephone number",
  "authSmsInputCaptionTimeLoad": "No verification code? %{time} seconds can be reissued",
  "authSmsInputCaptionTimeReload": "No captcha? Now resend",
  "authSmsInvaidPhoneFormat": "The mobile phone is not in the correct format. please confirm and try again.",
  "authSmsNavHeaderRightButton": "Next",
  "authSmsRequestTooFrequent": "Please try again later.",
  "authSmsSendVerifyCode": "Verification code has been sent, please pay attention to mobile phone messages.",
  "authSmsTitleDes": "Please enter your phone number",
  "authSmsVerifyIncorrectToken": "The mobile password input is not correct, please confirm and try again.",
  "authSmsVerifyReloadAuthSmsRequestButton": "Resend",
  "authSmsVerifyTitleDes": "Please enter the verification code",
  "authSmsVerifyTokenNotFound": "The cell phone verification code could not be found",

  "campaignProductDetailModalHasInventoryDanger": "Let me know.",
  "campaignProductDetailModalHasInventoryPrimary": "Into the shopping cart.",
  "campaignProductReservationModalButtonSubmit": "Submit",
  "campaignProductReservationModalTitleA": "Notify me of arrival",
  "campaignProductReservationModalTitleB": "Tell us how many pieces you will order in advance?",
  "campaignProductReservationModalTitleC": "We will inform you as soon as possible after replenishment in the form of a short message",
  "campaignProductUserQuotaModalInfo": "This item is limited to one purchase per user within 7 days ( calculated at 10 : 00 per day ) in order to make it easy for more households to purchase.",
  "campaignProductUserQuotaModalTitle": "Restricted goods",

  "checkNotificationAlertLeft": "Push settings",
  "checkNotificationAlertMessage": "We suggest that you turn on push notification. You can turn it on again in \"push settings\"",
  "checkNotificationAlertRight": "Cancel",

  "female": "Female",

  "helpModalContactUsTitle": "Contact us",
  "helpModalInfo": "This self-service food machine provides quick-frozen meals for registered members. Please put your member card QR code on the scanner, and then you can choose any box of quick-frozen meals on the machine. Please heat it up after taking it and enjoy.",
  "helpModalTitle": "Welcome use",
  "helpModalVersion": "Version",
  "helpModalVersionCloud": "servo",

  "homeAddShopCartCampaignProductSuccess": "Successfully added to the car",

  "homeDrawerEXIT": "Log Out",
  "homeDrawerExit": "Exit",
  "homeDrawerHELP": "Help",
  "homeDrawerMessageRecord": "Messages",
  "homeDrawerOrder": "My Order",
  "homeDrawerSETING_PASSWORD": "Change Password",
  "homeDrawerSetLocale": "Change Language",
  "homeDrawerWelcome": "Hello",
  "homeDrawer__AFDSI": "Automated Food Dispenser Service Introduction",
  "homeDrawer__AFDS_APP_INTRODUCTION": "AFDS App Introduction",
  "homeDrawer__CONTACT_US": "Contact Us",
  "homeDrawer__COOK_CHILL_MEALS": "Cook-chill Meals",
  "homeDrawer__FOOD_SAFETY_ISSUES": "Food Safety Issues",

  "homeListItemInventory": "Remaining (box)",
  "homeListItemKioskSaleTextDanger": "in maintenance",
  "homeListItemKioskSaleTextSuccess": "in operation",

  "homeNoProductsInfo": "There is no open pre-sale activity",

  "homePostCampaignProductReservationSuccess": "We will inform you as soon as possible after replenishment.",

  "homeTabContetUserQuota": "Limit purchases %{userquota} ",
  "homeTabContethasInventoryText": "Replenishment",
  "homeTabTitleAll": "ALl",

  "inventoryDetailsFoodViewButton": "Top",
  "inventoryDetailsFoodViewInventoryText": "Inventory",
  "inventoryDetailsTitle": "Inventory details",

  "loadingContentText": "Your healthy community platform",

  "loadingErrorButton": "Reload",
  "loadingErrorInfo": "Load error, please refresh the page.",

  "male": "Male",

  "myListEmptyComponent__title": "No data",

  "navigator__backButtonLabel": "back",

  "networkError": "Your network is abnormal, please try again later.",

  "orderDetailsCanceledOrderSuccess": "Order cancellation successful",
  "orderDetailsDeleteOrderOrerNotDeletable": "This order cannot be deleted",
  "orderDetailsDeleteOrderSuccess": "Order deleted successfully",

  "orderScreenTabTitleAll": "All",
  "orderScreenTabTitleComplete": "Complete",
  "orderScreenTabTitleExpireOrCanceled": "Invalid",
  "orderScreenTabTitlePendingOrPaid": "Unfinished",

  "pageBoxErrorButton": "Refresh",
  "pageBoxErrorText": "Load error, refresh the interface.",

  "passwordLogin__INCORRECT_PASSWORD": "The password is incorrect. Please try again",
  "passwordLogin__INVALID_PASSWORD": "The password is not set. Please set the password",
  "passwordLogin__INVALID_USER": "Mobile number does not exist",

  "projectBriefDetail": "As the COVID-19 pandemic has brought unprecedented challenges and led to growing unemployment and underemployment, there is a rising demand for food assistance from the needy in the general public. To support the local community, The Hong Kong Jockey Club Charities Trust launched the “Jockey Club Food Assistance Programme”, in conjunction with five other NGOs to provide transitional and timely relief for people facing financial hardship through a variety of food assistance initiatives. \n\nFood Angel launched the Automated Food Dispensers service in various districts. This innovative initiative allows for flexible collection of cook-chill meals supporting those with irregular working hours, and it aims to relief some financial burden the needy families face.  \n \nEligible applicants approved by social workers are distributed a digital membership card. With the QR code, members are able to collect cook-chill meals from the automate food dispensers. The meals can be easily reheated with rice cooker, microwave, or work at home.",
  "projectBriefDetailWebText": "For application and eligibility, please visit the website",
  "projectBriefTitle": "Project Introduction",

  "shopCartNoDataViewInfo": "For the time being, there's nothing.",
  "shopCartPostOrderCampaignProductAlreadyOrderedRecently": "Some restricted products have recently been listed, please check again.",
  "shopCartTitle": "Your shopping cart",

  "shpCartListFooterText": "Total% {amount} products",

  "today": "today",

  "userResidentialAddressGroupFootButton": "Can't find your house? Tell us.",
  "userResidentialAddressGroupFootButtonRequest": "Have received your submission, please keep informed",
  "userResidentialAddressGroupModalButtonCancel": "Cancel",
  "userResidentialAddressGroupModalButtonSubmit": "Submit",
  "userResidentialAddressGroupModalInputCaption": "The team will consider your estate in the future and encourage you to make suggestions to your owners' corporation or management office.",
  "userResidentialAddressGroupModalInputLabel": "Name of estate",
  "userResidentialAddressGroupModalTitle": "Tell us where you live.",
  "userResidentialAddressGroupNavHeaderRightButton": "Next",
  "userResidentialAddressGroupSelectCaption": "Once your estate has been selected, no changes can be made. Please pay attention to it.",
  "userResidentialAddressGroupSelectLabel": "Housing estates",
  "userResidentialAddressGroupTitleDes": "Your housing estate",
  "userResidentialAddressGroupUserRequestAlreadyExists": "We have received your submission, please pay attention to our facebook page update",

  "userSettingInfoInputLabel": "Name",
  "userSettingInfoNavHeaderRightButton": "Next",
  "userSettingInfoSelectLabel": "Gender",
  "userSettingInfoTitleDes": "About you."
}