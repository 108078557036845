import { Collapse, Divider, List, ListItemButton, ListItemButtonTypeMap, ListItemIcon, ListItemText, ListSubheader, SwipeableDrawer, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts";
import { ExpandLess, ExpandMore, StarBorder, ChevronRightTwoTone } from "@mui/icons-material";

//语言ICON
import GTranslateTwoToneIcon from '@mui/icons-material/GTranslateTwoTone';
//帮助ICON
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import { I18n, setLocale } from "react-redux-i18n";
import { setConfigData } from "../../actions";
import { DrawerItem } from "../../components/DrawerItem";
import { DrawerGroup } from "../../components/DrawerGroup";
import { HomeHelpModal } from "./HomeHelpModal";
import { HomeProjectBriefModal } from "./HomeProjectBriefModal";

export type HomeDrawerProps = {
  open?: boolean
  onClose?(): void
}

export type MenuKey =
  | 'LOCALE_EN'
  | 'LOCALE_ZN_TW'
  | 'LOCALE_ZH_CN'
  | 'PROJECT_BRIEF'//計劃簡介
  | 'AFDS_APP_INTRODUCTION' //自在食手機程序簡介
  | 'COOK_CHILL_MEALS' //速凍餐簡介
  | 'FOOD_SAFETY_ISSUES'//食品安全指引
  | 'CONTACT_US'//聯絡我們


type Menu = {
  title: string
  key?: MenuKey
  Icon?: any
  children?: Menu[]
  disabled?: boolean
}



export const HomeDrawer = (props: HomeDrawerProps) => {

  const dispatch = useAppDispatch()
  const config = useAppSelector(state => state.config)

  const [helpModal, setHelpModal] = useState<{
    key?: any
    open?: boolean
  }>({});
  const [homeProjectBriefModal, setHomeProjectBriefModal] = useState<{
    key?: any
    open?: boolean
  }>({});



  useEffect(() => {
    (async () => {
      console.log('HomeDrawer')
    })()
  }, [])




  const muneDatas: Menu[] = [
    {
      title: I18n.t('homeDrawerSetLocale'),
      Icon: <GTranslateTwoToneIcon />,
      children: [
        {
          title: 'English',
          key: 'LOCALE_EN',
          disabled: config.language === 'en',
        },
        {
          title: '繁體中文',
          key: 'LOCALE_ZN_TW',
          disabled: config.language === 'zh-tw',
        },
        {
          title: '简体中文',
          key: 'LOCALE_ZH_CN',
          disabled: config.language === 'zh-cn',
        },
      ],
    },
    {
      title: I18n.t('projectBriefTitle'),
      key: 'PROJECT_BRIEF',
    },
    {
      title: I18n.t('homeDrawerHELP'),
      Icon: <HelpTwoToneIcon />,
      children: [
        {
          title: I18n.t('homeDrawer__AFDS_APP_INTRODUCTION'),
          key: 'AFDS_APP_INTRODUCTION',
        },
        {
          title: I18n.t('homeDrawer__COOK_CHILL_MEALS'),
          key: 'COOK_CHILL_MEALS',
        },
        {
          title: I18n.t('homeDrawer__FOOD_SAFETY_ISSUES'),
          key: 'FOOD_SAFETY_ISSUES',
        },
        {
          title: I18n.t('homeDrawer__CONTACT_US'),
          key: 'CONTACT_US',
        },
      ],
    },
  ]

  const onAction = async (key?: MenuKey) => {
    switch (key) {
      case 'LOCALE_EN': {

        dispatch(setConfigData({
          ...config,
          language: 'en',
        }))
      }
        break
      case 'LOCALE_ZH_CN': {
        dispatch(setConfigData({
          ...config,
          language: 'zh-cn',
        }))
      }
        break
      case 'LOCALE_ZN_TW': {
        dispatch(setConfigData({
          ...config,
          language: 'zh-tw',
        }))
      }
        break
      case 'PROJECT_BRIEF': {
        // 計劃簡介 
        setHomeProjectBriefModal(
          {
            key: Date.now(),
            open: true,
          }
        )
      }
        break
      case 'AFDS_APP_INTRODUCTION': {
        // 自在食手機程序簡介
        const urlZh = 'https://submit.afds.foodangel.org.hk/static/media/app-introduction-zh.pdf'
        const urlEn = 'https://submit.afds.foodangel.org.hk/static/media/app-introduction-en.pdf'
        const url = config.language === 'en' ? urlEn : urlZh
        window.open(url)
      }
        break
      case 'COOK_CHILL_MEALS': {
        // 速凍餐簡介
        const urlZh = 'https://submit.afds.foodangel.org.hk/static/media/cook-chill-meal-zh.pdf'
        const urlEn = 'https://submit.afds.foodangel.org.hk/static/media/cook-chill-meal-en.pdf'
        const url = config.language === 'en' ? urlEn : urlZh
        window.open(url)
      }
        break
      case 'FOOD_SAFETY_ISSUES': {
        // 食品安全指引
        const urlZh = 'https://submit.afds.foodangel.org.hk/static/media/%E3%80%8C%E8%87%AA%E5%9C%A8%E9%A3%9F%E3%80%8D%E6%9C%8D%E5%8B%99%E4%BD%BF%E7%94%A8%E8%80%85%E9%A3%9F%E7%89%A9%E5%AE%89%E5%85%A8%E8%81%B2%E6%98%8E.pdf'
        const urlEn = 'https://submit.afds.foodangel.org.hk/static/media/05.%20AFD11_Food%20Angel%20AFD%20food%20safety%20agreement.pdf'
        const url = config.language === 'en' ? urlEn : urlZh
        window.open(url)
      }
        break
      case 'CONTACT_US': {
        // 聯絡我們
        setHelpModal(
          {
            key: Date.now(),
            open: true,
          }
        )
      }
        break
    }
  }

  return (
    <SwipeableDrawer
      anchor={'left'}
      open={props.open}
      onClose={props.onClose}
      onOpen={() => { }}
    >

      <List
        sx={{ width: 250, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >

        {
          muneDatas.map((muneData, index) => {

            if (muneData.children) {


              return (
                <div key={index}>
                  <DrawerGroup
                    title={muneData.title}
                    accessoryLeft={muneData.Icon}
                  >
                    <div>
                      {muneData.children.map((muneDataChildrenItem, muneDataChildrenIndex) => {
                        return (
                          <DrawerItem
                            key={muneDataChildrenIndex}
                            disabled={muneDataChildrenItem.disabled}
                            onPress={() => onAction(muneDataChildrenItem.key)}
                            title={muneDataChildrenItem.title}
                            accessoryRight={<ChevronRightTwoTone />}
                            boxProps={{
                              sx: { pl: 4 }
                            }}
                          />
                        )
                      })}
                    </div>

                  </DrawerGroup>
                  <Divider />

                </div>

              )


            } else {
              return (
                <div key={index}>
                  <DrawerItem
                    key={index}
                    disabled={muneData.disabled}
                    onPress={() => onAction(muneData.key)}
                    title={muneData.title}
                    accessoryRight={<ChevronRightTwoTone />}
                  />
                  <Divider />
                </div>

              )
            }

          })
        }




      </List>
      {helpModal.key && (
        <HomeHelpModal
          {...helpModal}
          onClose={() => {
            setHelpModal({
              open: false,
            })
          }}
        />
      )}

      {homeProjectBriefModal.key && (
        <HomeProjectBriefModal
          {...homeProjectBriefModal}
          onClose={() => {
            setHomeProjectBriefModal({
              open: false,
            })
          }}
        />
      )}


    </SwipeableDrawer>
  )
}





