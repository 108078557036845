import { Box, Dialog, Typography } from "@mui/material";
import { I18n } from "react-redux-i18n";
import { useAppSelector } from "../../store/hooks.ts";
import { color } from "../../theme";
import *as imgs from '../../imgs'

type HomeProjectBriefModalType = {
    open?: boolean
    onClose?(): void
    style?: React.CSSProperties
}

/**
 * 计划简介
 */
export const HomeProjectBriefModal = (props: HomeProjectBriefModalType) => {
    const { open, onClose, style } = props
    const config = useAppSelector(state => state.config)


    return (
        <Dialog
            open={open}
            onClose={onClose}
        >

            <Box
                style={{
                    ...style || {},
                    padding: 10,

                }}
            >

                <img
                    src={imgs.logoHkjcAllProject}
                    style={{
                        width: '100%',
                        // height: 200,
                    }}
                />

                <Box
                    style={{
                        width: '100%',
                        flexDirection: 'column',
                        display: "flex",
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={imgs.logoAfds}
                        style={{
                            width: '50%',
                            textAlign: 'center',
                        }}

                    />
                </Box>


                <Box
                >
                    <Typography
                        variant='h6'
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                        }}
                    >
                        {I18n.t('projectBriefTitle')}
                    </Typography>
                    <Typography
                        variant='body2'
                        style={{
                        }}
                    >
                        {I18n.t('projectBriefDetail')}
                    </Typography>
                    <Typography
                        variant='body2'
                        style={{
                            marginTop: 20,
                        }}
                    >
                        {I18n.t('projectBriefDetailWebText')}:
                    </Typography>


                    <Typography
                        style={{
                            marginTop: 10,
                            // color: color.primary_1
                        }}
                        variant='body2'
                    >
                        {I18n.t('Web')}:
                        <a
                            target="_blank"
                            href={
                                config.language !== 'en'
                                    ? `https://jcfoodprog.hk/zh`
                                    : `https://jcfoodprog.hk/en`
                            }>{
                                config.language !== 'en'
                                    ? `https://jcfoodprog.hk/zh`
                                    : `https://jcfoodprog.hk/en`
                            }
                        </a>
                    </Typography>

                </Box>


                <Box

                    style={{
                        flex: 2,
                        alignItems: 'center',
                        justifyContent: 'space-around',
                    }}
                >

                    <Box
                        style={{
                            marginTop: 20,

                        }}>
                        <Box
                            style={{ margin: 15, marginLeft: 0 }}>
                            <Typography style={{ fontSize: 13 }}>計劃夥伴:</Typography>
                            <Typography style={{ fontSize: 13 }}>Project partner:</Typography>
                        </Box>
                        <img
                            src={imgs.logoFoodAngel}
                            style={{
                                width: '60%',
                            }}
                        />
                    </Box>

                    <Box
                        style={{
                            marginTop: 20,
                        }}>
                        <Box
                        >
                            <Typography style={{ fontSize: 13 }}>捐款及策劃:</Typography>
                            <Typography style={{ fontSize: 13 }}>Funded and initiated by:</Typography>
                        </Box>
                        <img
                            src={imgs.logoHkjc}
                            style={{
                                width: '90%',
                            }}
                        />
                    </Box>

                </Box>

            </Box>


        </Dialog >
    )
}


