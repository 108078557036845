import { Box, Dialog, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { iconLogo } from "../../imgs";



type HomeQrModalType = {
    open?: boolean
    onClose?(event: {}, reason: "backdropClick" | "escapeKeyDown"): void
    style?: React.CSSProperties

    title: string
    qrCode: string

}

export const HomeQrModal = (props: HomeQrModalType) => {
    const { open, onClose, style, title, qrCode } = props
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <Box
                style={{
                    ...style || {},
                    padding: 10,
                }}
            >
                <Typography
                    variant="h6"
                    style={{
                        textAlign: 'center',
                        marginBottom: 10,
                        fontWeight: '800',
                    }}
                >
                    {title}
                </Typography>
                <QRCodeSVG
                    value={qrCode}
                    size={window.innerWidth * 0.7}
                    imageSettings={
                        {
                            src: iconLogo,
                            height: window.innerWidth * 0.15,
                            width: window.innerWidth * 0.15,
                            excavate: true,
                        }
                    }

                />



            </Box>
        </Dialog>
    )
}


