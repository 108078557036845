import { Box, Dialog, Typography } from "@mui/material";
import { I18n } from "react-redux-i18n";
import { useAppSelector } from "../../store/hooks.ts";
import { color } from "../../theme";
const { version } = require('../../../package.json')


type HomeHelpModalType = {
    open?: boolean
    onClose?(event: {}, reason: "backdropClick" | "escapeKeyDown"): void
    style?: React.CSSProperties
}

export const HomeHelpModal = (props: HomeHelpModalType) => {
    const { open, onClose, style } = props

    const metadata = useAppSelector(state => state.metadata)


    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <Box
                style={{
                    ...style || {},
                    padding: 10,
                }}
            >

                <Box>
                    <Typography variant='h6'>{I18n.t('appName')}</Typography>
                    <Typography
                        style={{
                            marginTop: 20,
                            letterSpacing: 1,
                        }}
                        variant={'body1'}
                    >{I18n.t('helpModalInfo')}</Typography>

                    <Typography style={{ marginTop: 20, color: color.primary_0 }} variant='body2'>{I18n.t('helpModalContactUsTitle')}</Typography>

                    <Typography
                        variant='body2'
                        style={{
                            marginTop: 10,
                        }}
                    >
                        {I18n.t('Tel')}: <a href="tel:57267209">57267209</a>
                    </Typography>
                    <Typography
                        style={{
                            marginTop: 10,
                            // color: color.primary_1
                        }}
                        variant='body2'
                    >
                        {I18n.t('Web')}: <a target="_blank" href="https://jcfoodprog.hk">https://jcfoodprog.hk</a>
                    </Typography>

                    <Typography
                        variant='body2'
                        style={{
                            marginTop: 10,
                        }}>{I18n.t('helpModalVersion')}: {version}</Typography>

                    <Typography
                        variant='body2'
                        style={{
                            marginTop: 10,
                        }}>{I18n.t('helpModalVersionCloud')}: {metadata.timezone}</Typography>

                </Box>


            </Box>
        </Dialog>
    )
}


