import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";
import { useEffect, useState } from "react";


type DrawerGroupProps = {
  title?: string
  accessoryLeft: JSX.Element
  children?: JSX.Element
}

export const DrawerGroup = (props: DrawerGroupProps) => {

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          {props.accessoryLeft}
        </ListItemIcon>
        <ListItemText
          primary={<span style={{ fontSize: 13, fontWeight: 450 }}>{props.title}</span>}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.children}
        </List>
      </Collapse>
    </>
  )

}


