

const authType = {
    UP_DATA: '[授权]更新数据',
    INIT_DATA: '[授权]初始化',

}
const configType = {
    UP_DATA: '[配置]更新数据',
    INIT_DATA: '[配置]初始化',

}
const userType = {
    UP_DATA: '[用户]更新数据',
    INIT_DATA: '[用户]初始化',

}
const metadataType = {
    UP_DATA: '[元数据]更新数据',
    INIT_DATA: '[元数据]初始化',

}
const labelType = {
    UP_DATA: '[產品標籤]更新数据',
    INIT_DATA: '[產品標籤]初始化',

}
const messageRecordType = {
    UP_DATA: '[消息记录]更新数据',
    INIT_DATA: '[消息记录]初始化',

}

export default {

    authType,//授权
    userType,//用户
    metadataType,//用户
    labelType,//產品標籤
    configType,//配置
    messageRecordType,//消息记录
}