/**
 * Created by gaocai on 16/9/19.
 */

import { combineReducers } from 'redux'
import Type from '../actions/action-type';
import { i18nReducer } from 'react-redux-i18n';
import { ActionConfigRes } from '../actions';
import { ActionUserRes } from '../actions/action-user';
import { ActionLabelRes } from '../actions/action-lable';
import { ActionMessageRecordRes } from '../actions/action-message-record';
import { ActionMetadataRes } from '../actions/action-metadata';


const authState = () => {
    return {
        isToken: true,
    }
}
const auth = (state = authState(), action: { type: any; }) => {
    switch (action.type) {
        case Type.authType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case Type.authType.INIT_DATA:
            return {
                ...authState()
            };
        default:
            return state;
    }
}

const configState = (): ActionConfigRes => {
    return {
        kioskInventories: [],
        language: 'zh-tw',
        areaIds: [],
    }
}
const config = (state = configState(), action: { type: any; }) => {
    switch (action.type) {
        case Type.configType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case Type.configType.INIT_DATA:
            return {
                ...configState()
            };
        default:
            return state;
    }
}

const userState = (): ActionUserRes => {
    return <any>{}
}
const user = (state = userState(), action: { type: any; }) => {
    switch (action.type) {
        case Type.userType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case Type.userType.INIT_DATA:
            return {
                ...userState()
            };
        default:
            return state;
    }
}

const labelState = (): ActionLabelRes => {
    return {
        data: {}
    }
}
const label = (state = labelState(), action: { type: any; }) => {
    switch (action.type) {
        case Type.labelType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case Type.labelType.INIT_DATA:
            return {
                ...labelState()
            };
        default:
            return state;
    }
}

const messageRecordState = (): ActionMessageRecordRes => {
    return {
        data: {},
    }
}
const messageRecord = (state = messageRecordState(), action: { type: any; }) => {
    switch (action.type) {
        case Type.messageRecordType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case Type.messageRecordType.INIT_DATA:
            return {
                ...messageRecordState()
            };
        default:
            return state;
    }
}

const metadataState = (): ActionMetadataRes => {
    return {
        version: 0,
        timezone: 480,
    }
}

const metadata = (state = metadataState(), action: { type: any; }) => {
    switch (action.type) {
        case Type.metadataType.UP_DATA:
            return {
                ...state,
                ...action,
            };
        case Type.metadataType.INIT_DATA:
            return {
                ...metadataState()
            };
        default:
            return state;
    }
}



export default combineReducers({
    i18n: i18nReducer,
    auth,
    user,
    label,
    config,
    messageRecord,
    metadata,
});