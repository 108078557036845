

import moment from 'moment';
import { GetHeadlthTipListRes, GetImageRes, GetKioskInventoryListRes, GetKioskInventoryRes } from './api-res-type';
import { DeleteKioskVendingUserStarsResult, GetHeadlthTipListResult, GetKioskInventoryListResult, GetLabelListResult, GetLabelResult, GetMessageRecordListResult, GetMessageRecordResult, GetMetadateResult, PostKioskVendingUserStarsResult, PostPushDeviceUserResult, PutMessageRecordResult } from './api-result-type';
let uuid = 0
const getId = () => {
    uuid++
    return `${uuid}`
}

const getBoolean = () => {
    return Boolean(Math.round(Math.random() * 1))
}


const textZhCn = '油麻地美食向來選擇甚多，沖繩漁民食堂主打的丼物及日本製讚岐烏冬是招牌名物，定價也非常親民，最平$48起就可以吃得到，超抵！ 如日本鰹魚花蟹籽紫菜溫泉蛋飯、照燒雞扒溫泉蛋飯、唐揚炸雞溫泉蛋飯及吉列豬扒溫泉蛋飯等，若然想配吃日本製讚岐烏冬只需加$10就可，彈性十足'
const textZhTw = '油麻地美食向來選擇甚多，沖繩漁民食堂主打的丼物及日本製讚岐烏冬是招牌名物，定價也非常親民，最平$48起就可以吃得到，超抵！ 如日本鰹魚花蟹籽紫菜溫泉蛋飯、照燒雞扒溫泉蛋飯、唐揚炸雞溫泉蛋飯及吉列豬扒溫泉蛋飯等，若然想配吃日本製讚岐烏冬只需加$10就可，彈性十足'
const texten = `Yau Ma Tei has always had a lot of choices for food. The well food featured in the Okinawa fishermen's canteen and Zanqi udong made in Japan are famous brands, and the price is also very close to the people. You can eat it from $48, which is more than enough! Such as Japanese skate, crab seed, seaweed, hot spring egg, roasted chicken, hot spring egg, Tangyang fried chicken, hot spring egg, Gillette, pork chop, hot spring, egg, rice, etc. if you want to eat Japanese Zanqi, Udon can only add $10.`

const image: GetImageRes = {
    id: '1111',
    url: 'https://img2.baidu.com/it/u=2247826721,2592659669&fm=26&fmt=auto',
    width: 100,
    height: 100,
    size: 100,
}

/**
 * 随机获取 10～20 之间的一个长度：
 * @param start  10
 * @param end  20
 */
const getText = (start: number, end: number) => {
    return {
        'zh-tw': textZhTw.slice(0, Math.round(Math.random() * (end - start) + start)),
        'zh-cn': textZhCn.slice(0, Math.round(Math.random() * (end - start) + start)),
        'en': texten.slice(0, Math.round(Math.random() * (end - start) + start)),
    }
}
const getTextAndDefault = (start: number, end: number) => {
    return {
        'zh-tw': textZhTw.slice(0, Math.round(Math.random() * (end - start) + start)),
        'zh-cn': textZhCn.slice(0, Math.round(Math.random() * (end - start) + start)),
        'en': texten.slice(0, Math.round(Math.random() * (end - start) + start)),
        'default': texten.slice(0, Math.round(Math.random() * (end - start) + start)),
    }
}

export const timeoutData = async<T>(data: T): Promise<T> => {
    return await new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(data)
        }, 200);
    })
}


export const GetKioskInventoryListResultData = async (start?: number | undefined, end?: number | undefined): Promise<GetKioskInventoryListResult> => {

    const totalCount = 34


    const data: GetKioskInventoryRes[] = [...Array(totalCount)]
        .map((item, index) => {
            const products = [
                {
                    title: {
                        'zh-tw': '洋蒽豬扒',
                        'zh-cn': '洋蒽豬扒',
                        'en': 'Port Chops With Onion',
                    },
                    labelIds: ['3'],
                },
                {
                    title: {
                        'zh-tw': '榨菜肉餅飯',
                        'zh-cn': '榨菜肉餅飯',
                        'en': 'Minced Pork And Pickled Mustard Tuber With Rice',
                    },
                    labelIds: ['2'],
                },
                {
                    title: {
                        'zh-tw': '中式牛仔骨',
                        'zh-cn': '中式牛仔骨',
                        'en': 'Beef Short Ribs With Sweet And Sour Sauce',
                    },
                    labelIds: ['1'],
                },
                {
                    title: {
                        'zh-tw': '青紅蘿蔔豬骨湯',
                        'zh-cn': '青紅蘿蔔豬骨湯',
                        'en': 'Boiled Pork Bone Soup With Green Radish And Carrot',
                    },
                    labelIds: ['2'],
                },
            ].map((aaa, index) => ({
                id: getId(),
                title: aaa.title,
                uniqueCode: getId(),
                labelIds: aaa.labelIds,
                totalAmount: Math.round(Math.random() * 30),
            }))
            return {
                id: getId(),
                kioskVendingId: getId(),
                title: getText(20, 200),
                createdAt: moment().add(index, 'day').toISOString(),
                kioskId: getId(),
                kioskName: '11111',
                kioskShopNo: getId(),
                kioskSaleState: getBoolean(),
                geolocation: null,
                amount: Math.round(Math.random() * 100),
                totalAmount: products.reduce((pV, cV) => pV + cV.totalAmount, 0),
                code: `XXX-01-XXX-00${index}`,
                address: {
                    'zh-tw': '新界青衣担杆山路6號長發邨長發廣場3樓320號舖',
                    'zh-cn': '新界青衣担杆山路6號長發邨長發廣場3樓320號舖',
                    'en': '2/F, Tuen Mun Town Plaza Phase II, 1 Tuen Shing Street, Tuen Mun, N.T.',
                },
                name: {
                    'zh-tw': 'Taste- 屯門市廣場 (麵包部右面，收銀處附近）',
                    'zh-cn': 'Taste- 屯門市廣場 (麵包部右面，收銀處附近）',
                    'en': 'Taste- Tuen Mun Plaza (right side of bakery, near cashier)',
                },
                products: getBoolean() ? products : [],
                isStars: getBoolean(),
                openHour: getText(5, 15),
            }
        })

    return await timeoutData<GetKioskInventoryListResult>({
        kind: 'ok',
        data: {
            data: data.slice(start, end && end ? end + end : undefined),
        }
    })
}



export const GetLabelListResultData = async (start?: number | undefined, end?: number | undefined): Promise<GetLabelListResult> => {
    const totalCount = 10

    return await timeoutData<GetLabelListResult>({
        kind: 'ok',
        data: {
            data: []
        }
    })
}

export const GetLabelResultData = async (start?: number | undefined, end?: number | undefined): Promise<GetLabelResult> => {



    return await timeoutData<GetLabelResult>({
        kind: 'ok',
        data: {
            id: '1',
            description: getText(10, 40),
            title: getText(5, 15),
            image: image,
            order: 1,
            createdAt: moment().toISOString(),
        }
    })
}


export const GetHeadlthTipListResultData = async (start?: number | undefined, end?: number | undefined): Promise<GetHeadlthTipListResult> => {


    return await timeoutData<GetHeadlthTipListResult>({
        kind: 'ok',
        data: [
            //    {
            //     id: getId(),
            //     title: getText(20, 200),
            //     createdAt: moment().toISOString(),
            //    }
        ]
    })
}
export const GetMessageRecordListResultData = async (start?: number | undefined, end?: number | undefined): Promise<GetMessageRecordListResult> => {

    const totalCount = 48
    const data: any[] = [...Array(totalCount)]
        .map((item, index) => (messageRecordData()))

    start = start || 0
    end = end || 0

    return await timeoutData<GetMessageRecordListResult>({
        kind: 'ok',
        data: {
            data: data.slice(start || undefined, end && end ? start + end : undefined),
            totalCount,
        }
    })

}

const messageRecordData = () => {
    return {
        id: getId(),
        message: {
            id: getId(),
            title: getTextAndDefault(20, 200),
            content: getTextAndDefault(20, 200),
            linkUrl: getBoolean() ? undefined : 'https://shimo.im/docs/m5kv9Bao4rCQZjqX',
            createdAt: moment().toISOString(),
        },
        readAt: getBoolean() ? undefined : moment().toISOString(),
        createdAt: moment().toISOString(),
        pushSentAt: moment().toISOString(),
    }
}

export const GetMessageRecordResultData = async (start?: number | undefined, end?: number | undefined): Promise<GetMessageRecordResult> => {


    return await timeoutData<GetMessageRecordResult>({
        kind: 'ok',
        data: messageRecordData()
    })
}
export const PutMessageRecordResultData = async (start?: number | undefined, end?: number | undefined): Promise<PutMessageRecordResult> => {


    return await timeoutData<PutMessageRecordResult>({
        kind: 'ok',
        data: {}
    })
}
export const PostPushDeviceUserResultData = async (start?: number | undefined, end?: number | undefined): Promise<PostPushDeviceUserResult> => {


    return await timeoutData<PostPushDeviceUserResult>({
        kind: 'ok',
        data: {}
    })
}
export const PostKioskVendingUserStarsResultData = async (start?: number | undefined, end?: number | undefined): Promise<PostKioskVendingUserStarsResult> => {


    return await timeoutData<PostKioskVendingUserStarsResult>({
        kind: 'ok',
        data: {
            id: getId(),
        }
    })
}
export const DeleteKioskVendingUserStarsResultData = async (start?: number | undefined, end?: number | undefined): Promise<DeleteKioskVendingUserStarsResult> => {


    return await timeoutData<DeleteKioskVendingUserStarsResult>({
        kind: 'ok',
        data: {}
    })
}
export const GetMetadateResultData = async (start?: number | undefined, end?: number | undefined): Promise<GetMetadateResult> => {


    return await timeoutData<GetMetadateResult>({
        kind: 'ok',
        data: {
            version: 3000000,
            timezone: 480,
        }
    })
}