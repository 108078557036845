import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/configure-store';
import { Provider } from 'react-redux';
import { toolGetStore } from './tool';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
toolGetStore(store)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#7FC241'
    }
  },
});

root.render(
  <React.StrictMode>
    <Provider
      store={store}
    >
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
