




class Config  {
    REACT_APP_API = String(process.env['REACT_APP_API'])
    isEnableTestAPIData = false

}

const config = new Config()
export default config


