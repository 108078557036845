import Type from './action-type';
import api from '../api';
import { MyThunkDispatch } from '.';
import *as actionUser from './action-user'

const updataActon = (data: any) => {
    return {
        type: Type.authType.UP_DATA, ...data
    }
}

const initDataAction = (): any => {
    return {
        type: Type.authType.INIT_DATA
    }
}

/**
 * 设置用户令牌
 * false 授权路由
 * true 主路由
 */
export const setUserToken = (isToken: boolean) => {
    return async (dispatch: MyThunkDispatch) => {
        await new Promise((resolve) => {
            setTimeout(() => {
                dispatch(updataActon({ isToken }))
                resolve({})
            }, 2000);
        })

    }
}

/**
 * 退出登录
 */
export const deleteAuth = () => {
    return async (dispatch: MyThunkDispatch) => {
        dispatch(setUserToken(false))
        await api.DeleteAppAuth()
    }
}










