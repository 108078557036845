
import api, { GetKioskInventoryRes } from "../../api";
import { ActionConfigRes, setConfigData } from "../../actions";
import { HomeInventoryItem, HomeInventoryItemType } from "./HomeInventoryItem";


type HomeKioskInventoryListType = {
    kioskInventories: GetKioskInventoryRes[]
    style?: React.CSSProperties
    config: ActionConfigRes
    onPressItem?(data: HomeInventoryItemType['kioskInvntory']): void
    onAddress?(data: HomeInventoryItemType['kioskInvntory']): void

}

export const HomeKioskInventoryList = (props: HomeKioskInventoryListType) => {
    const {
        kioskInventories,
        style,
        config,
        onPressItem,
        onAddress,
    } = props
    return (
        <div
            style={style}
        >
            {
                kioskInventories.map((item, index) => {
                    return (
                        <HomeInventoryItem
                            style={{
                                margin: 10,
                            }}
                            key={index}
                            kioskInvntory={item}
                            config={config}
                            onAddress={data => onAddress && onAddress(data)}
                            onPressItem={data => onPressItem && onPressItem(data)}
                        />
                    )
                })
            }
        </div>
    )
}


