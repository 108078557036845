import { MenuItem, Select, Stack, Typography } from "@mui/material";
import { color } from "../../theme";
import api, { GetAreaRes } from "../../api";
import { getI18nText } from "../../public";
import { ActionConfigRes } from "../../actions";



type HomeSelectType = {

    value?: string

    data: ((GetAreaRes & {
        kioskCount: number;
    }) | undefined)[]

    onValue(id: string): void
    config: ActionConfigRes
}

export const HomeSelect = (props: HomeSelectType) => {
    const { value, data, onValue, config } = props
    return (

        <Select
            displayEmpty={false}
            value={value}
            onChange={event => {
                onValue(event.target.value as string)
            }}
            style={{
                width: '100%',
                height: 35,
                color: color.secondary_1_0,
                backgroundColor: 'white',
            }}
        >
            {data.map((area, index) => {
                return (
                    <MenuItem
                        value={area.id}
                        key={area.id}
                        style={{
                        }}
                    >
                        {`${getI18nText(area.name, config.language)} (${area.kioskCount})`}
                    </MenuItem>
                )
            })}
        </Select>
    )
}
