import { Box, CircularProgress } from "@mui/material"
import { Button, ErrorBlock } from "antd-mobile"
import { useEffect, useState } from "react"

type PageBoxType = {
    children?: JSX.Element,
    nav?: JSX.Element,
    onLoadData(): Promise<any>
}

export const PageBox = (props: PageBoxType) => {
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const loadData = async () => {
        try {
            setLoading(true)
            await props.onLoadData()
            setLoading(false)
            setErrorMessage(undefined)
        } catch (error) {
            setErrorMessage(error.message)
        }
    }

    useEffect(() => {
        (async () => {
            await loadData()
        })()
    }, [])

    if (errorMessage) {
        return (
            <ErrorBlock
                fullPage
                description={errorMessage}
            >
                <Button onClick={loadData} color='primary'>Loading..</Button>
            </ErrorBlock>
        )
    }

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {props.nav}
            {loading
                ? (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </div>
                )
                : (
                    <div
                        style={{
                            flex: 1,
                            overflow: 'scroll',
                        }}
                    >
                        {props.children}
                    </div>
                )
            }

        </div>
    )
}
