import {  ListItemButton, ListItemIcon, ListItemText, } from "@mui/material";



type DrawerItemProps = {
  title?: string
  disabled?: boolean
  onPress?(): void
  accessoryLeft?: JSX.Element
  accessoryRight?: JSX.Element
  boxProps?: any
}

export const DrawerItem = (props: DrawerItemProps) => {

  return (
    <ListItemButton
      {...props.boxProps}
      disabled={props.disabled} onClick={props.onPress}>
      <ListItemIcon>
        {props.accessoryLeft}
      </ListItemIcon>
      <ListItemText
        primary={<span style={{ fontSize: 13, fontWeight: 450 }}>{props.title}</span>}
      />
      {props.accessoryRight}
    </ListItemButton>
  )

}



