export default {
  "            <DrawerItem disabled={false} onPress={() => this._onActions('COOK_CHILL_MEALS')} title={I18n.t('homeDrawer__COOK_CHILL_MEALS')} accessoryRight={IconAngleRight} />": "DRAWER_ITEM_DISABLED_FALSE_ON_PRESS_THIS_ON_ACTIONS_COOK_CHILL_MEALS_TITLE_I_18_N_T_HOME_DRAWER_COOK_CHILL_MEALS_ACCESSORY_RIGHT_ICON_ANGLE_RIGHT",

  "Auth_ButtonSmsFirst": "首次登录",
  "Auth_ButtonSmsForget": "忘记密码",

  "FoodCardItem__beginAt": "服务启用日",
  "FoodCardItem__dayInfo": "今天剩额",
  "FoodCardItem__endAt": "最后服务日",
  "FoodCardItem__latestUseAt": "上次取餐",
  "FoodCardItem__monthInfo": "本月剩额",
  "FoodCardItem__weekInfo": "本周剩額",

  "FoodMenuItem__footText": "此为今天补货后的餐单",
  "FoodMenuItem__headerMore": "更多餐单",
  "FoodMenu__title": "餐单",

  "HomeDrawer__exitModalCancel": "取消",
  "HomeDrawer__exitModalDefine": "确定",
  "HomeDrawer__exitModalMessage": "确定登出吗？",
  "HomeDrawer__exitModalTitle": "登出",

  "Home__foodCardUserMemberViewTitle": "你的家庭会员卡 (%{number}张)",
  "Home__foodMenuViewTitle": "自在食餐单",

  "Home__kioskInventoriesTitle": "你附近的自助提取机",

  "Home__labelButton": "查阅营养标签",

  "MessageRecordList__headerRightTitle": "%{number} 个未读",
  "MessageRecordList__listEmptyComponentTitle": "未有任何消息",
  "MessageRecordList__tabTitleSystem": "系统",
  "MessageRecordList__tabTitleUser": "个人",
  "MessageRecordList__title": "消息中心",

  "OrderDetailsScreenTitle": "订单详情",

  "OrderScreenTitle": "我的订单",

  "PasswordChangeByPassword__onSubmitSuccess": "修改成功",
  "PasswordChangeByPassword__passwordNewAInputPlaceholder": "请输入新的密码",
  "PasswordChangeByPassword__passwordOldInputPlaceholder": "请输入密码",
  "PasswordChangeByPassword__title": "更改密码",
  "PasswordChangeBySms__INCORRECT_PASSWORD": "密码不正确,请重试",
  "PasswordChangeBySms__INCORRECT_SMS_CODE": "短信验证码不正确",
  "PasswordChangeBySms__INVALID_USER": "手机号码不存在",
  "PasswordChangeBySms__TOO_MUCH_TIME": "请求过于频繁,请60秒后重试.",
  "PasswordChangeBySms__footButton": "已经设置过密码?",
  "PasswordChangeBySms__loginButton": "确定",
  "PasswordChangeBySms__passwordInputPlaceholder": "新的密码",
  "PasswordChangeBySms__renderPasswordCaption": "请输入6~16位长度的新密码",
  "PasswordChangeBySms__smsInputRightButton": "发送验证",
  "PasswordChangeBySms__smsInputRightButtonByNumber": "重发 (%{number})",
  "PasswordChangeBySms__title": "设置密码",

  "PasswordLogin__footButton": "第一次登入?",
  "PasswordLogin__loginButton": "登录",
  "PasswordLogin__passwordInputPlaceholder": "请输入已经设置过的密码",
  "PasswordLogin__phoneInputPlaceholder": "请输入已经登记电话",
  "PasswordLogin__renderPasswordCaption": "这是你首次登入时自定义的密码",
  "PasswordLogin__smsByPasswordButton": "设置密码",
  "PasswordLogin__smsInputPlaceholder": "短信验证码",
  "PasswordLogin__title": "密码登录",

  "ProductDetails_label": "标签",
  "ProductDetails_product": "产品",

  "ProductLabel__title": "营养标签",

  "ShopCartListRendertItemContetUserQuota": "限购 %{userQuota} 件",

  "Tel": "电话",

  "UserMessageModal__ButtonClose": "关闭",
  "UserMessageModal__ButtonNext": "下一条",
  "UserMessageModal__textSurplus": "剩余未读(%{count})",
  "UserMessageModal__title": "新的讯息",

  "UserScreenTitle": "个人资讯",

  "Web": "网页",

  "addShopCartCampaignProductHasInventory": "抱歉,货品没有足够的库存了",
  "addShopCartCampaignProductHasUserQuota": "抱歉！已達到購買上限",
  "addShopCartCampaignProductRecentOrderAllowedAt": "您最近已经预购过了, 请稍后再下单",

  "appName": "惜食堂 自在食",

  "authButtonSms": "手提电话号码",

  "authInfoPrivacyPolicy": "隐私政策",
  "authInfoTermsOfUse": "使用条款",
  "authInfoTitle": "继续使用 即表示您同意遵守",

  "authSelectAuthText": "请选择你的注册或登录方式",

  "authSmsInputCaption": "请输入正确的电话号码",
  "authSmsInputCaptionTimeLoad": "没收到验证码？%{time}  秒后可重发",
  "authSmsInputCaptionTimeReload": "没收到验证码？现在重发",
  "authSmsInvaidPhoneFormat": "手提电话格式不正确，请确认后重试。",
  "authSmsNavHeaderRightButton": "下一步",
  "authSmsRequestTooFrequent": "验证简讯请求太频繁,请稍后重试。",
  "authSmsSendVerifyCode": "已经发送验证码，请留意手机短信。",
  "authSmsTitleDes": "请输入手机号码",
  "authSmsVerifyIncorrectToken": "手机验证码输入不正确，请确定后重试。",
  "authSmsVerifyReloadAuthSmsRequestButton": "重新发送",
  "authSmsVerifyTitleDes": "请输入验证码",
  "authSmsVerifyTokenNotFound": "找不到该手机验证码",

  "campaignProductDetailModalHasInventoryDanger": "到货通知我",
  "campaignProductDetailModalHasInventoryPrimary": "加入到购物车",
  "campaignProductReservationModalButtonSubmit": "提交",
  "campaignProductReservationModalTitleA": "到货通知我",
  "campaignProductReservationModalTitleB": "告诉我们您将会预购多少件?",
  "campaignProductReservationModalTitleC": "我们会在补货后, 以短讯形式第一时间通知您",
  "campaignProductUserQuotaModalInfo": "此货品为限购货品, 为了让更多住户可以容易购买到, 每位用户在 7 天内只能预购一次 (由每天 10 时计算)",
  "campaignProductUserQuotaModalTitle": "限购货品",

  "checkNotificationAlertLeft": "推送设置",
  "checkNotificationAlertMessage": "我们建议您开启推送通知，您可到“推送设置”再次开启",
  "checkNotificationAlertRight": "关闭",

  "female": "女士",

  "helpModalContactUsTitle": "联系我们",
  "helpModalInfo": "自助食物机是为已登记的会员提供速冻餐。请将取餐卡上的二维条码对准扫瞄器一扫，便可选择机上任何一盒速冻餐，取餐后请自行加热后立刻享用。  ",
  "helpModalTitle": "欢迎使用",
  "helpModalVersion": "版本",
  "helpModalVersionCloud": "伺服",

  "homeAddShopCartCampaignProductSuccess": "成功添加到购物车",

  "homeDrawerEXIT": "登出",
  "homeDrawerExit": "登出",
  "homeDrawerHELP": "帮助",
  "homeDrawerMessageRecord": "消息中心",
  "homeDrawerOrder": "我的订单",
  "homeDrawerSETING_PASSWORD": "更改密码",
  "homeDrawerSetLocale": "设置语言",
  "homeDrawerWelcome": "您好",
  "homeDrawer__AFDSI": "自助机简介",
  "homeDrawer__AFDS_APP_INTRODUCTION": "自在食手机程序简介",
  "homeDrawer__CONTACT_US": "联络我们",
  "homeDrawer__COOK_CHILL_MEALS": "速冻餐简介",
  "homeDrawer__FOOD_SAFETY_ISSUES": "食物安全指引",

  "homeListItemInventory": "尚余餐盒",
  "homeListItemKioskSaleTextDanger": "暂停服务",
  "homeListItemKioskSaleTextSuccess": "正常运作",

  "homeNoProductsInfo": "暂时未有开放的预售活动",

  "homePostCampaignProductReservationSuccess": "成功提交！我们会在补货后第一时间通知您",

  "homeTabContetUserQuota": "限购 %{userQuota} 件",
  "homeTabContethasInventoryText": "补货中",
  "homeTabTitleAll": "全部",

  "inventoryDetailsFoodViewButton": "置頂",
  "inventoryDetailsFoodViewInventoryText": "库存",
  "inventoryDetailsTitle": "库存详情",

  "loadingContentText": "你的健康社区平台",

  "loadingErrorButton": "重新加载",
  "loadingErrorInfo": "加载出错，请刷新页面。",

  "male": "男士",

  "myListEmptyComponent__title": "暂无数据",

  "navigator__backButtonLabel": "返回",

  "networkError": "你的网络异常，请稍后重试。",

  "orderDetailsCanceledOrderSuccess": "订单取消成功",
  "orderDetailsDeleteOrderOrerNotDeletable": "该订单不能够被删除",
  "orderDetailsDeleteOrderSuccess": "订单删除成功",

  "orderScreenTabTitleAll": "全部",
  "orderScreenTabTitleComplete": "已取货",
  "orderScreenTabTitleExpireOrCanceled": "已无效",
  "orderScreenTabTitlePendingOrPaid": "未完成",

  "pageBoxErrorButton": "刷新",
  "pageBoxErrorText": "加载错误，请刷新界面。",

  "passwordLogin__INCORRECT_PASSWORD": "密码不正确,请重试.",
  "passwordLogin__INVALID_PASSWORD": "密码没有设置,请去设置密码.",
  "passwordLogin__INVALID_USER": "手机号码不存在",

  "projectBriefDetail": "在新型冠状病毒疫情下，不同人士生计受到严重影响，食物援助需求有增无减。香港赛马会慈善信托基金有见及此，联同惜食堂及五间非牟利机构推行「赛马会逆境同行食物援助计划」，为合资格人士提供合适的过渡性食物援助，渡过今次难关。\n  \n惜食堂推行的「自在食」速冻餐自助提取机座落全港不同地区，提供创新及更具弹性的取餐方式，为不同作息时间的人士提供食物援助服务，以纾缓因疫情冲击而导致的经济压力。\n\n申请人经社作出审核后，会获派会员卡乙张，凭卡上二维码就可以到食物提取机领取速冻餐。回家后，用电饭煲、微波炉、镬等等加热后即可食用。",
  "projectBriefDetailWebText": "计划申请及须知请参照网页",
  "projectBriefTitle": "计划简介",

  "shopCartNoDataViewInfo": "暂时没有东西",
  "shopCartPostOrderCampaignProductAlreadyOrderedRecently": "部分限购产品最近已下过单，请检查重试。",
  "shopCartTitle": "你的购物车",

  "shpCartListFooterText": "总共%{amount}款产品",

  "today": "今天",

  "userResidentialAddressGroupFootButton": "找不到您的屋苑? 告诉我们吧",
  "userResidentialAddressGroupFootButtonRequest": "已收到你的提交，请留意最新消息",
  "userResidentialAddressGroupModalButtonCancel": "取消",
  "userResidentialAddressGroupModalButtonSubmit": "提交",
  "userResidentialAddressGroupModalInputCaption": "团队会于日后将您的屋苑加入考虑之中, 另外亦鼓励您向您的屋苑业主立案法团或管理处提出建议, 当我们收到他们的申请, 最快可以一星期内完成安排",
  "userResidentialAddressGroupModalInputLabel": "屋苑名称",
  "userResidentialAddressGroupModalTitle": "告诉我们您住的屋苑",
  "userResidentialAddressGroupNavHeaderRightButton": "下一步",
  "userResidentialAddressGroupSelectCaption": "一经选择您的屋苑后, 就不能作出更改, 敬请留意",
  "userResidentialAddressGroupSelectLabel": "屋苑",
  "userResidentialAddressGroupTitleDes": "您的屋苑",
  "userResidentialAddressGroupUserRequestAlreadyExists": "我们已收到您的提交，请留意我们Facebook Page 最新消息",

  "userSettingInfoInputLabel": "名字",
  "userSettingInfoNavHeaderRightButton": "下一步",
  "userSettingInfoSelectLabel": "性别",
  "userSettingInfoTitleDes": "关于您"
}