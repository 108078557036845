import { useLoaderData, useNavigation, Outlet, Navigate, useParams, useAsyncValue, redirect, useNavigate, useSearchParams } from "react-router-dom"
import { setConfigData } from "../../actions"
import { getMetadata } from "../../actions/action-metadata"
import { Nav, PageBox } from "../../components"
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts"


export const Root = () => {
    const { rootParams } = useParams() as {
        rootParams?: string
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const config = useAppSelector(state => state.config)
    console.log('pathname', window.location.search)
    let navigate = useNavigate();

    const dispatch = useAppDispatch()
    return (
        <>
            <PageBox
                onLoadData={async () => {
                    await dispatch(getMetadata())
                    setConfigData({
                        ...config,
                    })
                    navigate("/home");
                }}

            />
        </>
    )
}