export default {
  "            <DrawerItem disabled={false} onPress={() => this._onActions('COOK_CHILL_MEALS')} title={I18n.t('homeDrawer__COOK_CHILL_MEALS')} accessoryRight={IconAngleRight} />": "DRAWER_ITEM_DISABLED_FALSE_ON_PRESS_THIS_ON_ACTIONS_COOK_CHILL_MEALS_TITLE_I_18_N_T_HOME_DRAWER_COOK_CHILL_MEALS_ACCESSORY_RIGHT_ICON_ANGLE_RIGHT",

  "Auth_ButtonSmsFirst": "首次登入",
  "Auth_ButtonSmsForget": "忘記密碼",

  "FoodCardItem__beginAt": "服務啟用日",
  "FoodCardItem__dayInfo": "今天剩額",
  "FoodCardItem__endAt": "最後服務日",
  "FoodCardItem__latestUseAt": "上次取餐",
  "FoodCardItem__monthInfo": "本月剩額",
  "FoodCardItem__weekInfo": "本週剩額",

  "FoodMenuItem__footText": "此為今天補貨後的餐單",
  "FoodMenuItem__headerMore": "更多餐單",
  "FoodMenu__title": "餐單",

  "HomeDrawer__exitModalCancel": "取消",
  "HomeDrawer__exitModalDefine": "確定",
  "HomeDrawer__exitModalMessage": "確定登出嗎？",
  "HomeDrawer__exitModalTitle": "登出",

  "Home__foodCardUserMemberViewTitle": "你的家庭會員卡 (%{number}張)",
  "Home__foodMenuViewTitle": "自在食餐單",

  "Home__kioskInventoriesTitle": "你附近的自助提取機",

  "Home__labelButton": "查閱營養標籤",

  "MessageRecordList__headerRightTitle": "%{number} 個未讀",
  "MessageRecordList__listEmptyComponentTitle": "未有任何消息",
  "MessageRecordList__tabTitleSystem": "系統",
  "MessageRecordList__tabTitleUser": "個人",
  "MessageRecordList__title": "消息中心",

  "OrderDetailsScreenTitle": "訂單詳情",

  "OrderScreenTitle": "我的訂單",

  "PasswordChangeByPassword__onSubmitSuccess": "修改成功",
  "PasswordChangeByPassword__passwordNewAInputPlaceholder": "請輸入新的密碼",
  "PasswordChangeByPassword__passwordOldInputPlaceholder": "請輸入密碼",
  "PasswordChangeByPassword__title": "更改密碼",
  "PasswordChangeBySms__INCORRECT_PASSWORD": "密碼不正確，請重試",
  "PasswordChangeBySms__INCORRECT_SMS_CODE": "簡訊驗證碼不正確",
  "PasswordChangeBySms__INVALID_USER": "手機號碼不存在",
  "PasswordChangeBySms__TOO_MUCH_TIME": "請求過於頻繁，請60秒後重試.",
  "PasswordChangeBySms__footButton": "已經設置過密碼?",
  "PasswordChangeBySms__loginButton": "確定",
  "PasswordChangeBySms__passwordInputPlaceholder": "新的密碼",
  "PasswordChangeBySms__renderPasswordCaption": "請輸入6~16位長度的新密碼",
  "PasswordChangeBySms__smsInputRightButton": "發送驗證",
  "PasswordChangeBySms__smsInputRightButtonByNumber": "重發（%{number}）",
  "PasswordChangeBySms__title": "設置密碼",

  "PasswordLogin__footButton": "第一次登入?",
  "PasswordLogin__loginButton": "登入",
  "PasswordLogin__passwordInputPlaceholder": "請輸入已經設定過的密碼",
  "PasswordLogin__phoneInputPlaceholder": "請輸入已經登記電話",
  "PasswordLogin__renderPasswordCaption": "這是你首次登入時自定義的密碼",
  "PasswordLogin__smsByPasswordButton": "設置密碼",
  "PasswordLogin__smsInputPlaceholder": "簡訊驗證碼",
  "PasswordLogin__title": "密碼登入",

  "ProductDetails_label": "標籤",
  "ProductDetails_product": "產品",

  "ProductLabel__title": "營養標籤",

  "ShopCartListRendertItemContetUserQuota": "限購 %{userQuota} 件",

  "Tel": "電話",

  "UserMessageModal__ButtonClose": "關閉",
  "UserMessageModal__ButtonNext": "下一條",
  "UserMessageModal__textSurplus": "剩餘未讀(%{count})",
  "UserMessageModal__title": "新的訊息",

  "UserScreenTitle": "個人資訊",

  "Web": "網頁",

  "addShopCartCampaignProductHasInventory": "抱歉,貨品沒有足夠的庫存了",
  "addShopCartCampaignProductHasUserQuota": "抱歉！已达到购买上限",
  "addShopCartCampaignProductRecentOrderAllowedAt": "您最近已經預購過了, 請稍後再下單",

  "appName": "惜食堂 自在食",

  "authButtonSms": "手提電話號碼",

  "authInfoPrivacyPolicy": "隱私政策",
  "authInfoTermsOfUse": "使用條款",
  "authInfoTitle": "繼續使用 即表示您同意遵守",

  "authSelectAuthText": "請選擇你的註冊或登錄方式",

  "authSmsInputCaption": "請輸入正確的電話號碼",
  "authSmsInputCaptionTimeLoad": "沒收到驗證碼？%{time}  秒後可重發",
  "authSmsInputCaptionTimeReload": "沒收到驗證碼？現在重發",
  "authSmsInvaidPhoneFormat": "手機的格式不正確。請確認後再試。",
  "authSmsNavHeaderRightButton": "下一步",
  "authSmsRequestTooFrequent": "驗證簡訊請求太頻繁,請稍後重試。",
  "authSmsSendVerifyCode": "已經發送驗證碼，請留意手機簡訊。",
  "authSmsTitleDes": "請輸入手機號碼",
  "authSmsVerifyIncorrectToken": "手機驗證碼輸入不正確，請確定後重試。",
  "authSmsVerifyReloadAuthSmsRequestButton": "重新发送",
  "authSmsVerifyTitleDes": "請輸入驗證碼",
  "authSmsVerifyTokenNotFound": "找不到該手機驗證碼",

  "campaignProductDetailModalHasInventoryDanger": "到貨通知我",
  "campaignProductDetailModalHasInventoryPrimary": "加入到購物車",
  "campaignProductReservationModalButtonSubmit": "提交",
  "campaignProductReservationModalTitleA": "到貨通知我",
  "campaignProductReservationModalTitleB": "告訴我們您將會預購多少件?",
  "campaignProductReservationModalTitleC": "我們會在補貨後, 以短訊形式第一時間通知您",
  "campaignProductUserQuotaModalInfo": "此貨品為限購貨品, 為了讓更多住戶可以容易購買到, 每位用戶在 7 天內只能預購一次 (由每天 10 時計算)",
  "campaignProductUserQuotaModalTitle": "限購貨品",

  "checkNotificationAlertLeft": "推送設置",
  "checkNotificationAlertMessage": "我們建議您開啟推送通知, 您可到 \"推送設置\" 再次開啟",
  "checkNotificationAlertRight": "關閉",

  "female": "女士",

  "helpModalContactUsTitle": "聯繫我們",
  "helpModalInfo": "自助食物機是為已登記的會員提供速凍餐。請將取餐卡上的二維條碼對準掃瞄器一掃，便可選擇機上任何一盒速凍餐，取餐後請自行加熱後立刻享用。",
  "helpModalTitle": "歡迎使用",
  "helpModalVersion": "版本",
  "helpModalVersionCloud": "伺服",

  "homeAddShopCartCampaignProductSuccess": "成功添加到購物車",

  "homeDrawerEXIT": "登出",
  "homeDrawerExit": "登出",
  "homeDrawerHELP": "幫助",
  "homeDrawerMessageRecord": "消息中心",
  "homeDrawerOrder": "我的訂單",
  "homeDrawerSETING_PASSWORD": "更改密碼",
  "homeDrawerSetLocale": "設置語言",
  "homeDrawerWelcome": "您好",
  "homeDrawer__AFDSI": "自助機簡介",
  "homeDrawer__AFDS_APP_INTRODUCTION": "自在食手機程式簡介",
  "homeDrawer__CONTACT_US": "聯絡我們",
  "homeDrawer__COOK_CHILL_MEALS": "速凍餐簡介",
  "homeDrawer__FOOD_SAFETY_ISSUES": "食物安全指引",

  "homeListItemInventory": "尚餘餐盒",
  "homeListItemKioskSaleTextDanger": "暫停服務",
  "homeListItemKioskSaleTextSuccess": "正常運作",

  "homeNoProductsInfo": "暫時未有開放的預售活動",

  "homePostCampaignProductReservationSuccess": "成功提交！我們會在補貨後第一時間通知您",

  "homeTabContetUserQuota": "限購 %{userQuota} 件",
  "homeTabContethasInventoryText": "補貨中",
  "homeTabTitleAll": "全部",

  "inventoryDetailsFoodViewButton": "置顶",
  "inventoryDetailsFoodViewInventoryText": "庫存",
  "inventoryDetailsTitle": "庫存詳情",

  "loadingContentText": "你的健康社區平台",

  "loadingErrorButton": "重新加載",
  "loadingErrorInfo": "加載出錯，請刷新頁面。",

  "male": "男士",

  "myListEmptyComponent__title": "暫無數據",

  "navigator__backButtonLabel": "返回",

  "networkError": "你的網絡異常，請稍後重試。",

  "orderDetailsCanceledOrderSuccess": "訂單取消成功",
  "orderDetailsDeleteOrderOrerNotDeletable": "該訂單不能夠被刪除",
  "orderDetailsDeleteOrderSuccess": "訂單刪除成功",

  "orderScreenTabTitleAll": "全部",
  "orderScreenTabTitleComplete": "已取貨",
  "orderScreenTabTitleExpireOrCanceled": "已無效",
  "orderScreenTabTitlePendingOrPaid": "未完成",

  "pageBoxErrorButton": "刷新",
  "pageBoxErrorText": "加載錯誤，請刷新界面。",

  "passwordLogin__INCORRECT_PASSWORD": "密碼不正確，請重試.",
  "passwordLogin__INVALID_PASSWORD": "密碼沒有設定，請去設置密碼.",
  "passwordLogin__INVALID_USER": "手機號碼不存在",

  "projectBriefDetail": "在新型冠狀病毒疫情下，不同人士生計受到嚴重影響，食物援助需求有增無減。香港賽馬會慈善信託基金有見及此，聯同惜食堂及五間非牟利機構推行「賽馬會逆境同行食物援助計劃」，為合資格人士提供合適的過渡性食物援助，渡過今次難關。 \n  \n惜食堂推行的「自在食」速凍餐自助提取機座落全港不同地區，提供創新及更具彈性的取餐方式，為不同作息時間的人士提供食物援助服務，以紓緩因疫情衝擊而導致的經濟壓力。 \n\n申請人經社作出審核後，會獲派會員卡乙張，憑卡上二維碼就可以到食物提取機領取速凍餐。回家後，用電飯煲、微波爐、鑊等等加熱後即可食用。",
  "projectBriefDetailWebText": "計劃申請及須知請參照網頁",
  "projectBriefTitle": "計劃簡介",

  "shopCartNoDataViewInfo": "暫時沒有東西",
  "shopCartPostOrderCampaignProductAlreadyOrderedRecently": "部分限購產品最近已下過單，請檢查重試。",
  "shopCartTitle": "你的購物車",

  "shpCartListFooterText": "總共%{amount}款產品",

  "today": "今天",

  "userResidentialAddressGroupFootButton": "找不到您的屋苑? 告訴我們吧",
  "userResidentialAddressGroupFootButtonRequest": "已收到你的提交，請留意最新消息",
  "userResidentialAddressGroupModalButtonCancel": "取消",
  "userResidentialAddressGroupModalButtonSubmit": "提交",
  "userResidentialAddressGroupModalInputCaption": "團隊會於日後將您的屋苑加入考慮之中, 另外亦鼓勵您向您的屋苑業主立案法團或管理處提出建議, 當我們收到他們的申請, 最快可以一星期內完成安排",
  "userResidentialAddressGroupModalInputLabel": "屋苑名稱",
  "userResidentialAddressGroupModalTitle": "告訴我們您住的屋苑",
  "userResidentialAddressGroupNavHeaderRightButton": "下一步",
  "userResidentialAddressGroupSelectCaption": "一經選擇您的屋苑後, 就不能作出更改, 敬請留意",
  "userResidentialAddressGroupSelectLabel": "屋苑",
  "userResidentialAddressGroupTitleDes": "您的屋苑",
  "userResidentialAddressGroupUserRequestAlreadyExists": "我們已收到您的提交，請留意我們Facebook Page 最新消息",

  "userSettingInfoInputLabel": "名字",
  "userSettingInfoNavHeaderRightButton": "下一步",
  "userSettingInfoSelectLabel": "性别",
  "userSettingInfoTitleDes": "關於您"
}