
// const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
import GL, { PhoneNumber, PhoneNumberType } from 'google-libphonenumber'

export const phoneUtil = GL.PhoneNumberUtil.getInstance()
/**
 *解析字符串并以原始缓冲区格式返回，同时保留原始输入值。
 */
export const toolParseAndKeepRawInput = (number: string, regionCode?: string) => phoneUtil.parseAndKeepRawInput(number, regionCode)

/**
 *测试电话号码是否对
 某个地区有效。
 */
export const toolIsValidNumberForRegion = (phoneNumber: PhoneNumber, region?: string) => phoneUtil.isValidNumberForRegion(phoneNumber, region)

/**
 *返回电话号码所在的区域。
 */
export const toolGetRegionCodeForNumber = (phoneNumber: PhoneNumber) => phoneUtil.getRegionCodeForNumber(phoneNumber)

/**
 * 
 * @param phonePrefixe 手机前缀
 * @param phone 手机号码
 */
export const toolCheckPhone = (phonePrefixe?: string, phone?: string): boolean => {

    try {
        // const number = `${phonePrefixe}-${phone}`
        // const phoneNumber = toolParseAndKeepRawInput(number)
        // return toolIsValidNumberForRegion(phoneNumber, toolGetRegionCodeForNumber(phoneNumber))
        return !!(phone && phone.length === 8)
    } catch (error) {
        return false
    }


}


// console.log('toolCheckPhone', toolCheckPhone('+852', '2123457'))
