import Type from './action-type';
import api, { GetMetadateRes } from '../api';
import { MyThunkDispatch, setConfigData, setUserToken } from '.';

const updataActon = (data: ActionMetadataRes) => {
    return {
        type: Type.metadataType.UP_DATA, ...data
    }
}

const initDataAction = (): any => {
    return {
        type: Type.metadataType.INIT_DATA
    }
}


export type ActionMetadataRes = GetMetadateRes

/**
 * 获取用户 并设置全局语言
 */
export const getMetadata = () => {
    return async (dispatch: MyThunkDispatch) => {
        const res = await api.getMetadate()
        if (res.kind !== 'ok') {
            throw new Error(res.kind)
        }
        dispatch(updataActon(res.data))
        return res.data
    }
}


export const initData = () => {
    return async (dispatch: MyThunkDispatch) => {
        dispatch(initDataAction())
    }
}













